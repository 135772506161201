import { Button, Card, CardBody, CardHeader, Col, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import React, { useState, useContext } from 'react';
import jwt_decode from 'jwt-decode';
import { useTranslation } from 'react-i18next';
import AppleLogin from 'react-apple-login';
import GoogleLogin from 'react-google-login';

import UserContext from '../../context/UserContext';
import { login, socialLogin } from '../../network/ApiAxios';
import appleLogo from 'assets/img/icons/common/apple.svg';
import googleLogo from 'assets/img/icons/common/google.svg';

const Login = (props) => {
  const { t, i18n } = useTranslation();

  const { setUser } = useContext(UserContext);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const doLogin = (data) => {
    if (data?.success) {
      setError('');
      localStorage.setItem('token', data.token);

      if (data.user?.accountSettings?.locale) {
        i18n.changeLanguage(data.user.accountSettings.locale);
      }
      setUser(data.user);
      props.history.push('/');
    } else {
      setPassword('');
      setError(t('Unknown Error Occurred!'));
    }
  };

  const tryLogin = async () => {
    try {
      const { data } = await login(email, password);
      doLogin(data);
    } catch (err) {
      setError(t(err));
      console.log(err);
    }
  };

  const responseGoogle = async (response) => {
    console.log(response);
    if (!response.error) {
      try {
        const { profileObj, googleId } = response;
        const user = {
          firstName: profileObj.givenName,
          lastName: profileObj.familyName,
          email: profileObj.email,
          image: profileObj.imageUrl,
          googleId: googleId,
        };
        const { data } = await socialLogin(user);
        doLogin(data);
      } catch (err) {
        setError(t(err));
        console.log(err);
      }
    }
  };

  const responseApple = async (response) => {
    console.log(response);
    if (!response.error) {
      try {
        var decoded = jwt_decode(response.authorization.id_token);

        const user = {
          firstName: response?.user?.firstName,
          lastName: response?.user?.lastName,
          email: response?.user?.email,
          appleId: decoded.sub,
        };
        const { data } = await socialLogin(user);
        doLogin(data);
      } catch (err) {
        setError(t(err));
        console.log(err);
      }
    }
  };

  return (
    <>
      <Col lg='5' md='7' className='login-page'>
        <Card className='bg-secondary shadow border-0'>
          <CardHeader className='bg-transparent pb-5'>
            <div className='text-muted text-center mt-2 mb-3'>
              <small>{t('Sign in with')}</small>
            </div>
            <div className='btn-wrapper text-center'>
              <AppleLogin
                clientId='com.leventixweb.signin'
                redirectURI='https://backend.leventix.de/auth/login'
                scope='name email'
                state='initial'
                usePopup={true}
                responseType={'code'}
                responseMode={'query'}
                callback={responseApple}
                render={(renderProps) => (
                  <Button className='btn-neutral btn-icon' color='default' onClick={renderProps.onClick} disabled={renderProps.disabled}>
                    <span className='btn-inner--icon'>
                      <img alt='...' src={appleLogo} />
                    </span>
                    <span className='btn-inner--text'>Apple</span>
                  </Button>
                )}
              />

              <GoogleLogin
                clientId='438932273371-0uvrgurcsjr14t307ais2sqp5mr5lu9a.apps.googleusercontent.com'
                render={(renderProps) => (
                  <Button className='btn-neutral btn-icon' color='default' onClick={renderProps.onClick} disabled={renderProps.disabled}>
                    <span className='btn-inner--icon'>
                      <img alt='...' src={googleLogo} />
                    </span>
                    <span className='btn-inner--text'>Google</span>
                  </Button>
                )}
                buttonText='Login'
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy={'single_host_origin'}
              />
            </div>
          </CardHeader>
          <CardBody className='px-lg-5 py-lg-5'>
            <div className='text-center text-muted mb-4'>
              <small>{t('Or sign in with credentials')}</small>
            </div>
            <Form role='form'>
              <FormGroup className='mb-3'>
                <InputGroup className='input-group-alternative'>
                  <InputGroupAddon addonType='prepend'>
                    <InputGroupText>
                      <i className='ni ni-email-83' />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder={t('Email')} type='email' autoComplete='email' value={email} onChange={(e) => setEmail(e.target.value)} />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className='input-group-alternative'>
                  <InputGroupAddon addonType='prepend'>
                    <InputGroupText>
                      <i className='ni ni-lock-circle-open' />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder={t('Password')}
                    type='password'
                    autoComplete='password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <p className='text-center'>
                <small className='text-muted'>
                  Mit deiner Anmeldung oder der Erstellung eines Kontos stimmst du unseren <b>Nutzungsbedinungen</b> zu. In unserer Datenschutz- und
                  Cookies Richtlinie erfährst du mehr darüber, wie wir deine Daten verarbeiten.
                </small>
              </p>
              {/*<div className="custom-control custom-control-alternative custom-checkbox">*/}
              {/*    <input*/}
              {/*        className="custom-control-input"*/}
              {/*        id=" customCheckLogin"*/}
              {/*        type="checkbox"*/}
              {/*    />*/}
              {/*    <label*/}
              {/*        className="custom-control-label"*/}
              {/*        htmlFor=" customCheckLogin"*/}
              {/*    >*/}
              {/*        <span className="text-muted">Remember me</span>*/}
              {/*    </label>*/}
              {/*</div>*/}
              {error ? (
                <div className='text-muted font-italic'>
                  <small>
                    Error: <span className='text-red font-weight-700'>{error}</span>
                  </small>
                </div>
              ) : null}
              <div className='text-center'>
                <Button className='mt-1 mb-4' color='primary' type='button' onClick={tryLogin}>
                  {t('Sign in')}
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        <Row className='mt-3'>
          <Col xs='6'>
            <span className='text-light cursor-pointer' onClick={() => props.history.push('/auth/reset-password')}>
              <small>{t('Forgot password?')}</small>
            </span>
          </Col>
          <Col className='text-right cursor-pointer' xs='6'>
            <span className='text-light cursor-pointer' onClick={() => props.history.push('/auth/register')}>
              <small>{t('Create new account')}</small>
            </span>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default Login;
