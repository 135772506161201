import React, { useState, useEffect, useContext } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment-timezone';
// import 'moment/locale/en';
import 'moment/locale/de';
// reactstrap components
import { Card, CardHeader, Container, Row, CardBody, FormGroup, InputGroup, Input, InputGroupAddon, InputGroupText } from 'reactstrap';
// i18n
import { useTranslation } from 'react-i18next';
// core components
import { fetchUserEvents, deleteEvent } from '../network/ApiAxios';
import Header from 'components/Headers/Header.js';
import AlertContext from 'context/AlertContext';
import UserContext from 'context/UserContext';

const AllEvents = (props) => {
  moment.locale('en');
  moment.updateLocale('en');
  // Set the IANA time zone you want to use
  moment.tz.setDefault('Europe/Berlin');

  const localizer = momentLocalizer(moment);

  const { user } = useContext(UserContext);
  const { updateAlertData } = useContext(AlertContext);

  const { t, i18n } = useTranslation();

  const [events, setEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);

  // getUserEvents
  const getUserEvents = async () => {
    try {
      const { data } = await fetchUserEvents();
      const list = [];
      for (const iterator of data) {
        list.push({
          id: iterator._id,
          title: iterator.title,
          start: moment(iterator.startDate).toDate(),
          end: moment(iterator.endDate).toDate(),
        });
      }
      setEvents(list);
      setFilteredEvents(list);
    } catch (error) {
      updateAlertData({ success: false, message: error.msg || 'Unknown Error Occurred!' });
    }
  };

  // initial load
  useEffect(() => {
    getUserEvents();
  }, []);

  // handleDeleteEvent
  const handleDeleteEvent = async (event) => {
    try {
      if (event?.target?.dataset?.id) {
        const { data } = await deleteEvent(event.target.dataset.id);
        // refetch events
        getUserEvents();
        updateAlertData({ success: true, message: t(data.msg) });
      }
    } catch (error) {
      updateAlertData({ success: false, message: error.msg || 'Unknown Error Occurred!' });
    }
  };

  const handleEditEvent = (event) => {
    if (event?.target?.dataset?.id) {
      props.history.push(`/admin/edit-event/${event.target.dataset.id}`);
    }
  };

  // handleFilterEvents
  const handleFilterEvents = (event) => {
    if (event.target.value === '') {
      setFilteredEvents(events);
    } else {
      const filterEvents = events.filter((e) => e.title.includes(event.target.value));
      setFilteredEvents(filterEvents);
    }
  };

  // EventAgenda Component
  function EventAgenda({ event }) {
    return (
      <div className='d-flex justify-content-between'>
        <b>{event.title}</b>
        <div>
          <i className='far fa-edit cursor-pointer' data-id={event.id} onClick={handleEditEvent} />
          {user.userType === 'superAdmin' ? (
            <i className='ml-3 far fa-trash-alt cursor-pointer' data-id={event.id} onClick={handleDeleteEvent}></i>
          ) : null}
        </div>
      </div>
    );
  }

  return (
    <>
      <Header hideStats={true} />
      {/* Page content */}
      <Container className='mt--7' fluid>
        {/* Table */}
        <Row>
          <div className='col'>
            <Card className='shadow'>
              <CardHeader className='border-0 d-flex justify-content-between'>
                <h3 className='mb-0'>{t('All Your Events')}</h3>

                <FormGroup className='mb-0'>
                  <InputGroup className=''>
                    <Input placeholder={t('Search Events')} type='text' onChange={handleFilterEvents} />
                    <InputGroupAddon addonType='append'>
                      <InputGroupText>
                        <i className='fas fa-search' />
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </CardHeader>
              <CardBody>
                <div>
                  <Calendar
                    style={{ height: 800 }}
                    localizer={localizer}
                    events={filteredEvents}
                    startAccessor='start'
                    endAccessor='end'
                    culture={i18n.language}
                    // onSelectEvent={(e) => (window.location.href = `/admin/edit-event/${e.id}`)}
                    components={{
                      agenda: {
                        event: EventAgenda,
                      },
                    }}
                    messages={{
                      date: t('Date'),
                      time: t('Time'),
                      event: t('Event'),
                      allDay: t('All Day'),
                      week: t('Week'),
                      work_week: t('Work Week'),
                      day: t('Day'),
                      month: t('Month'),
                      previous: t('Back'),
                      next: t('Next'),
                      yesterday: t('Yesterday'),
                      tomorrow: t('Tomorrow'),
                      today: t('Today'),
                      agenda: t('Agenda'),
                      noEventsInRange: t('There are no events in this range.'),
                      showMore: function (e) {
                        return '+' + e + t(' more');
                      },
                    }}
                  />
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default AllEvents;
