import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  CustomInput,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from 'reactstrap';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import React, { useContext, useEffect, useState } from 'react';
import { fetchEventById, fetchHashTags, updateEvent } from '../network/ApiAxios';

import AlertContext from 'context/AlertContext';
import UserContext from 'context/UserContext';
import Header from 'components/Headers/Header.js';
import ReactDatetime from 'react-datetime';
import Select from 'react-select';
import _ from 'lodash';
import cn from 'clsx';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const regularityMapping = {
  Weekly: [
    { value: 1, label: 'Monday' },
    { value: 2, label: 'Tuesday' },
    { value: 3, label: 'Wednesday' },
    { value: 4, label: 'Thursday' },
    { value: 5, label: 'Friday' },
    { value: 6, label: 'Saturday' },
    { value: 7, label: 'Sunday' },
  ],
  Monthly: _.range(1, 32).map((x) => ({ label: x, value: x })),
};

const EditEventPage = () => {
  const { t, i18n } = useTranslation();

  const { user } = useContext(UserContext);
  const nonBasicUser = user?.userSubscription === 'light' || user?.userSubscription === 'premium' || user?.userType === 'superAdmin';

  const { updateAlertData } = useContext(AlertContext);

  const [isLoading, setIsLoading] = useState(false);
  const [eventId, setEventId] = useState(null);
  const [eventInfo, setEventInfo] = useState({});
  const [categoryList, setCategoryList] = useState([]);
  const [formData, setFormData] = useState({});
  const [eventImage, setEventImage] = useState(null);

  const [showEventSeries, setShowEventSeries] = useState(false);
  const [eventRegularity, setEventRegularity] = useState('Weekly');
  const [eventSeriesChoice, setEventSeriesChoice] = useState([]);

  useEffect(() => {
    const eventId = window.location.pathname.replace('/admin/edit-event/', '');
    if (!eventId) {
      return window.location.replace('/admin/events');
    }
    setEventId(eventId);
  }, []);

  useEffect(() => {
    if (eventId) {
      (async () => {
        const { data: hashtags } = await fetchHashTags();
        const list = [];
        for (const iterator of hashtags.hashTags) {
          list.push({ value: iterator._id, label: iterator.title });
        }
        setCategoryList(list);

        const { data: eventInfo } = await fetchEventById(eventId);
        if (!eventInfo) {
          return window.location.replace('/admin/events');
        }
        if (user.userType !== 'superAdmin' && eventInfo.organizer._id !== user._id) {
          return window.location.replace('/admin/events');
        }
        if (eventInfo.eventSeries) {
          setShowEventSeries(true);
          const cron = eventInfo.eventSeries.split(' ');
          if (cron[5] !== '*') {
            setEventRegularity('Weekly');
            setEventSeriesChoice(cron[5].split(',').map(Number));
          } else {
            setEventRegularity('Monthly');
            setEventSeriesChoice(cron[3].split(',').map(Number));
          }
        }
        setEventInfo(eventInfo);

        setFormData({
          ...eventInfo,
          hashTags: _.map(eventInfo.hashTags, '_id'),
          currency: formData?.ticketPricing && formData?.ticketPricing[0]?.currency,
        });
      })();
    }
  }, [eventId]);

  // remove additionalDates if series exists
  useEffect(() => {
    if (showEventSeries) {
      const newFormData = _.cloneDeep(formData);
      newFormData.additionalDates = [];
      setFormData(newFormData);
    }
  }, [showEventSeries]);

  // save image onchange to state
  const uploadHandler = (event) => {
    setEventImage(event.target.files[0]);
  };

  // handle form fields changes
  const handleChange = (name, value, index, subName) => {
    const newFormData = _.cloneDeep(formData);
    if (name === 'ticketPricing') {
      newFormData.ticketPricing[index][subName] = value;
    } else if (name === 'additionalDates') {
      newFormData.additionalDates[index][subName] = value;
    } else {
      newFormData[name] = value;
    }
    setFormData(newFormData);
  };

  // handleTicketPricing
  const handleTicketPricing = (action, index) => {
    const newFormData = _.cloneDeep(formData);
    if (action === 'add') {
      if (!newFormData.ticketPricing) {
        newFormData.ticketPricing = [];
      }
      newFormData.ticketPricing.push({ name: null, price: null, count: null });
    } else {
      newFormData.ticketPricing.splice(index, 1);
    }
    setFormData(newFormData);
  };

  // handleAdditionalDates
  const handleAdditionalDates = (action, index) => {
    const newFormData = _.cloneDeep(formData);
    if (action === 'add') {
      if (!newFormData.additionalDates) {
        newFormData.additionalDates = [];
      }
      newFormData.additionalDates.push({ startDate: new Date(), endDate: new Date() });
    } else {
      newFormData.additionalDates.splice(index, 1);
    }
    setFormData(newFormData);
  };

  // handleEditEvent
  const handleEditEvent = async () => {
    try {
      setIsLoading(true);
      let newFormData = _.cloneDeep(formData);
      if (
        !newFormData ||
        !newFormData.title ||
        !newFormData.description ||
        !newFormData.location ||
        !newFormData.startDate ||
        !newFormData.endDate ||
        !newFormData.hashTags.length
      ) {
        updateAlertData({ success: false, message: 'Incorrect parameters received!' });
        setIsLoading(false);
        return;
      }
      // check event series
      if (showEventSeries) {
        if (!eventSeriesChoice.length || !newFormData.seriesEndDate) {
          updateAlertData({ success: false, message: 'Incorrect parameters received!' });
          setIsLoading(false);
          return;
        }
      }
      // check additionalDates
      if (newFormData?.additionalDates?.length) {
        for (let index = 0; index < formData.additionalDates.length; index++) {
          if (
            !moment(formData.additionalDates[index].startDate, moment.ISO_8601).isValid() ||
            !moment(formData.additionalDates[index].endDate, moment.ISO_8601).isValid()
          ) {
            updateAlertData({ success: false, message: 'Incorrect parameters received!' });
            setIsLoading(false);
            return;
          }
        }
      }

      if (newFormData.location.type !== 'Point' || newFormData.location.label) {
        newFormData.locationLabel = newFormData.location.label;
        newFormData.location = {
          type: 'Point',
          coordinates: [newFormData?.location.geometry.location.lng(), newFormData?.location.geometry.location.lat()],
        };
      }

      newFormData.organizer = newFormData.organizer._id;

      delete newFormData.eventAttendees;
      delete newFormData.eventReviews;
      delete newFormData.similarEvents;

      if (newFormData.currency) {
        newFormData.ticketPricing?.map((tp) => (tp.currency = newFormData.currency));
      }
      delete newFormData.currency;

      // remove below dynamic added fields
      delete newFormData.eventSeries;
      delete newFormData.image;

      const payloadFormData = new FormData();
      for (const key in newFormData) {
        payloadFormData.append(key, JSON.stringify(newFormData[key]));
      }
      if (eventImage) {
        payloadFormData.append('image', eventImage);
      }
      // event series
      if (showEventSeries) {
        let cron = ['*', '*', '*', '*', '*', '*'];
        if (eventRegularity === 'Weekly') {
          cron[5] = eventSeriesChoice.join(',');
        } else {
          cron[3] = eventSeriesChoice.join(',');
        }
        cron = cron.join(' ');
        payloadFormData.append('eventSeries', JSON.stringify(cron));
      } else {
        payloadFormData.append('eventSeries', JSON.stringify(null));
      }

      await updateEvent(newFormData._id, payloadFormData);
      updateAlertData({ success: true, message: 'Event Updated Successfully' });
      // setIsLoading(false);
      setTimeout(() => {
        window.location.replace('/admin/events');
      }, 500);
    } catch (error) {
      setIsLoading(false);
      updateAlertData({ success: false, message: error.msg || 'Unknown Error Occurred!' });
    }
  };

  return (
    <>
      <Header hideStats={true} />
      {/* Page content */}
      <Container className='mt--7' fluid>
        <Card className='bg-secondary shadow'>
          <CardHeader className='bg-white border-0'>
            <Row className='align-items-center'>
              <Col xs='8'>
                <h3 className='mb-0'>{t('Edit Event')}</h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Form>
              <br />
              <div className='pl-lg-4'>
                <Row>
                  <Col lg='6'>
                    <FormGroup>
                      <label className='form-control-label' htmlFor='input-title'>
                        {t('Event Title')}*
                      </label>
                      <Input
                        className='form-control-alternative'
                        id='input-title'
                        placeholder={t('Event Title')}
                        onChange={(e) => handleChange('title', e.target.value)}
                        value={formData.title}
                      />
                    </FormGroup>

                    <FormGroup>
                      <label>{t('Event Short Description')}*</label>
                      <Input
                        className='form-control-alternative'
                        placeholder={t('Max. 100 signs')}
                        type='textarea'
                        maxLength='100'
                        rows={1}
                        onChange={(e) => handleChange('description', e.target.value)}
                        value={formData.description}
                      />
                    </FormGroup>

                    <FormGroup>
                      <label>{t('Event Details')}</label>
                      <Input
                        className='form-control-alternative'
                        placeholder={t('Event Details')}
                        rows='4'
                        type='textarea'
                        onChange={(e) => handleChange('details', e.target.value)}
                        value={formData.details}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg='6'>
                    <FormGroup>
                      <label className='form-control-label' htmlFor='input-category'>
                        {t('Event Category')}*
                        <small className='text-muted ml-2'>
                          ({t('Max')} {nonBasicUser ? 5 : 3} {t('Categories')})
                        </small>
                      </label>
                      <Select
                        isMulti
                        name='category'
                        options={formData?.hashTags?.length > (nonBasicUser ? 4 : 2) ? [] : categoryList}
                        placeholder={t('Select')}
                        styles={{
                          menu: (provided) => ({ ...provided, zIndex: 5 }),
                        }}
                        onChange={(e) => handleChange('hashTags', _.map(e, 'value'))}
                        value={categoryList?.filter((option) => formData?.hashTags?.includes(option.value))}
                      />
                    </FormGroup>

                    <FormGroup>
                      <label className='form-control-label'>{t('Event Location')}*</label>
                      <GooglePlacesAutocomplete
                        selectProps={{
                          placeholder: `${t('Select')}`,
                          onChange: async (val) => {
                            let geocode = await geocodeByPlaceId(val.value.place_id);
                            geocode = geocode[0];
                            geocode.label = val.label;
                            handleChange('location', geocode);
                          },
                        }}
                      />
                      <span className='text-muted'>{formData?.locationLabel}</span>
                    </FormGroup>

                    <FormGroup className='row'>
                      <Col lg='6'>
                        <label className='form-control-label'>{t('Image')}</label>
                        <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', paddingBottom: '10px' }}>
                          <CustomInput
                            type='file'
                            id='image'
                            name='image'
                            label={t('Choose file')}
                            className={cn('form-control', i18n.language)}
                            style={{ zIndex: 1 }}
                            onChange={uploadHandler}
                          />
                        </div>
                        <div className='d-flex justify-content-between'>
                          <div className='custom-control custom-checkbox mb-3'>
                            <input className='custom-control-input' defaultChecked disabled type='checkbox' />
                            <label className='custom-control-label'>{t('Yes, I own the rights to this image')}</label>
                          </div>
                          <small className='text-muted ml-2'>(.png, .jpg, .jpeg, .webp)</small>
                        </div>

                        <input
                          className='form-control-alternative form-control'
                          placeholder={t('Citation')}
                          onChange={(e) => handleChange('imageCitation', e.target.value)}
                          style={{ width: '300px', height: 'unset' }}
                          value={formData.imageCitation}
                        />
                      </Col>
                      <Col lg='6'>
                        {eventInfo?.image ? <img src={eventInfo?.image.replace(/\\/g, '/')} alt='event-image' height='150px' /> : null}
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>

                <hr className='mt-1 mb-4' />

                {/* date selections */}
                <Row>
                  <Col lg='6'>
                    <Row>
                      <FormGroup className='col-lg-6'>
                        <label className='form-control-label'>{t('Event Start Date')}*</label>
                        <InputGroup className='input-group-alternative'>
                          <InputGroupAddon addonType='prepend'>
                            <InputGroupText>
                              <i className='ni ni-calendar-grid-58' />
                            </InputGroupText>
                          </InputGroupAddon>
                          <ReactDatetime
                            inputProps={{ placeholder: t('Event Start Date') }}
                            dateFormat='DD.MM.YYYY'
                            timeFormat='HH:mm'
                            isValidDate={(current) => (formData?.endDate ? current < moment(formData?.endDate).add(1, 'days') : true)}
                            onChange={(e) =>
                              e
                                ? moment(e, moment.ISO_8601).isValid()
                                  ? handleChange('startDate', e.toISOString())
                                  : handleChange('startDate', null)
                                : null
                            }
                            value={moment(formData.startDate)}
                          />
                        </InputGroup>
                      </FormGroup>

                      <FormGroup className='col-lg-6'>
                        <label className='form-control-label'>{t('Event End Date')}*</label>
                        <InputGroup className='input-group-alternative'>
                          <InputGroupAddon addonType='prepend'>
                            <InputGroupText>
                              <i className='ni ni-calendar-grid-58' />
                            </InputGroupText>
                          </InputGroupAddon>
                          <ReactDatetime
                            inputProps={{ placeholder: t('Event End Date') }}
                            dateFormat='DD.MM.YYYY'
                            timeFormat='HH:mm'
                            isValidDate={(current) => current > moment(formData?.startDate || new Date()).subtract(1, 'days')}
                            onChange={(e) =>
                              e
                                ? moment(e, moment.ISO_8601).isValid()
                                  ? handleChange('endDate', e.toISOString())
                                  : handleChange('endDate', null)
                                : null
                            }
                            value={moment(formData.endDate)}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Row>

                    {formData?.additionalDates?.map((tp, i) => (
                      <div key={i}>
                        <Row className='align-items-center'>
                          <FormGroup className='col-lg-5'>
                            <label className='form-control-label'>
                              {t('Additional')} {t('Event Start Date')}
                            </label>
                            <InputGroup className='input-group-alternative'>
                              <InputGroupAddon addonType='prepend'>
                                <InputGroupText>
                                  <i className='ni ni-calendar-grid-58' />
                                </InputGroupText>
                              </InputGroupAddon>
                              <ReactDatetime
                                inputProps={{ placeholder: `${t('Additional')} ${t('Event Start Date')}` }}
                                dateFormat='DD.MM.YYYY'
                                timeFormat='HH:mm'
                                isValidDate={(current) =>
                                  formData?.additionalDates[i]?.endDate
                                    ? current < moment(formData?.additionalDates[i]?.endDate).add(1, 'days')
                                    : true
                                }
                                onChange={(e) =>
                                  moment(e, moment.ISO_8601).isValid()
                                    ? handleChange('additionalDates', e.toISOString(), i, 'startDate')
                                    : handleChange('additionalDates', null, i, 'startDate')
                                }
                                value={moment(formData.additionalDates[i]?.startDate)}
                              />
                            </InputGroup>
                          </FormGroup>

                          <FormGroup className='col-lg-5'>
                            <label className='form-control-label'>
                              {t('Additional')} {t('Event End Date')}
                            </label>
                            <InputGroup className='input-group-alternative'>
                              <InputGroupAddon addonType='prepend'>
                                <InputGroupText>
                                  <i className='ni ni-calendar-grid-58' />
                                </InputGroupText>
                              </InputGroupAddon>
                              <ReactDatetime
                                inputProps={{ placeholder: `${t('Additional')} ${t('Event End Date')}` }}
                                dateFormat='DD.MM.YYYY'
                                timeFormat='HH:mm'
                                isValidDate={(current) => current > moment(formData?.additionalDates[i]?.startDate || new Date()).subtract(1, 'days')}
                                onChange={(e) =>
                                  moment(e, moment.ISO_8601).isValid()
                                    ? handleChange('additionalDates', e.toISOString(), i, 'endDate')
                                    : handleChange('additionalDates', null, i, 'endDate')
                                }
                                value={moment(formData.additionalDates[i]?.endDate)}
                              />
                            </InputGroup>
                          </FormGroup>

                          <Button className='mt-2' color='danger' onClick={() => handleAdditionalDates(null, i)} size='sm'>
                            <i className='fas fa-trash'></i>
                          </Button>
                        </Row>
                      </div>
                    ))}

                    {(formData?.additionalDates?.length || 0) < 10 && (
                      <Button color='default' size='sm' type='button' onClick={() => handleAdditionalDates('add')} disabled={showEventSeries}>
                        <i className='far fa-calendar-plus mr-2' />
                        <span className='btn-inner--text'>{t('Add another date')}</span>
                      </Button>
                    )}
                  </Col>
                  <Col lg='6'>
                    <div className='custom-control custom-checkbox mb-3'>
                      <input
                        className='custom-control-input'
                        id='create-event-series'
                        type='checkbox'
                        onChange={(e) => setShowEventSeries(e.target.checked)}
                        disabled={formData?.additionalDates?.length || 0}
                        checked={showEventSeries}
                      />
                      <label className='custom-control-label font-weight-bold' htmlFor='create-event-series'>
                        {t('Create event series')}
                      </label>
                    </div>

                    {/* event date series */}
                    {showEventSeries && (
                      <Row>
                        <FormGroup className='col-lg-3'>
                          <label className='form-control-label'>{t('Regularity')}*</label>
                          <select
                            // name='currency'
                            className='form-control-alternative form-control w-100'
                            style={{ width: '100px' }}
                            onChange={(e) => {
                              setEventRegularity(e.target.value);
                              setEventSeriesChoice([]);
                            }}
                          >
                            <option value='Weekly'>Weekly</option>
                            <option value='Monthly'>Monthly</option>
                          </select>
                        </FormGroup>

                        <FormGroup className='col-lg-9'>
                          <label className='form-control-label'>{t('Choose')}*</label>
                          <Select
                            isMulti
                            // name='category'
                            options={regularityMapping[eventRegularity]?.map((x) => ({ ...x, label: t(x.label) })) || []}
                            placeholder={t('Choose')}
                            styles={{
                              menu: (provided) => ({ ...provided, zIndex: 5 }),
                              control: (provided) => ({ ...provided, lineHeight: '30px' }),
                            }}
                            onChange={(e) => setEventSeriesChoice(_.map(e, 'value'))}
                            value={regularityMapping[eventRegularity]?.filter((o) => eventSeriesChoice?.includes(o.value))}
                          />
                        </FormGroup>

                        <FormGroup className='col-lg-12'>
                          <label className='form-control-label'>{t('Series End Date')}*</label>
                          <InputGroup className='input-group-alternative'>
                            <InputGroupAddon addonType='prepend'>
                              <InputGroupText>
                                <i className='ni ni-calendar-grid-58' />
                              </InputGroupText>
                            </InputGroupAddon>
                            <ReactDatetime
                              inputProps={{ placeholder: t('Series End Date') }}
                              isValidDate={(current) => current > moment()}
                              onChange={(e) =>
                                moment(e, moment.ISO_8601).isValid()
                                  ? handleChange('seriesEndDate', e.toISOString())
                                  : handleChange('seriesEndDate', null)
                              }
                              value={moment(formData?.seriesEndDate)}
                            />
                          </InputGroup>
                        </FormGroup>
                      </Row>
                    )}
                  </Col>
                </Row>
              </div>
              <hr className='my-4' />
              <Row>
                <Col lg='3'>
                  <div className='custom-control custom-control-alternative custom-checkbox mb-3'>
                    <input
                      className='custom-control-input'
                      type='checkbox'
                      id='ParkingFacilities'
                      onChange={(e) => handleChange('parkingFacilities', e.target.checked)}
                      checked={formData.parkingFacilities}
                    />
                    <label className='custom-control-label' htmlFor='ParkingFacilities'>
                      {t('Parking Facilities')}
                    </label>
                  </div>
                  {/* <div className='custom-control custom-control-alternative custom-checkbox mb-3'>
                    <input
                      className='custom-control-input'
                      type='checkbox'
                      id='EnableReviews'
                      onChange={(e) => handleChange('reviewsAllowed', e.target.checked)}
                      checked={formData.reviewsAllowed}
                    />
                    <label className='custom-control-label' htmlFor='EnableReviews'>
                      {t('Enable Reviews')}
                    </label>
                  </div> */}
                  <div className='custom-control custom-control-alternative custom-checkbox mb-3'>
                    <input
                      className='custom-control-input'
                      type='checkbox'
                      id='BoxOfficeAvailable'
                      onChange={(e) => handleChange('boxOffice', e.target.checked)}
                      checked={formData.boxOffice}
                    />
                    <label className='custom-control-label' htmlFor='BoxOfficeAvailable'>
                      {t('Box Office Available')}
                    </label>
                  </div>

                  <div className='custom-control custom-control-alternative custom-checkbox mb-3'>
                    <input
                      className='custom-control-input'
                      type='checkbox'
                      id='paidEntry'
                      onChange={(e) => handleChange('paidEntry', e.target.checked)}
                      checked={formData.paidEntry}
                    />
                    <label className='custom-control-label' htmlFor='paidEntry'>
                      {t('Paid Entry')}
                    </label>
                    <span className='ml-3'>
                      {formData?.paidEntry ? (
                        <input
                          className='form-control-alternative form-control'
                          type='number'
                          placeholder='Euro €'
                          onChange={(e) => handleChange('entryFee', e.target.value)}
                          style={{ width: '100px', height: 'unset', display: 'unset' }}
                          value={formData.entryFee}
                        />
                      ) : null}
                    </span>
                  </div>

                  <div className='custom-control custom-control-alternative custom-checkbox mb-3'>
                    <input
                      className='custom-control-input'
                      type='checkbox'
                      id='FavoriteEvent'
                      onChange={(e) => handleChange('isTrending', e.target.checked)}
                      checked={formData.isTrending}
                    />
                    <label className='custom-control-label' htmlFor='FavoriteEvent'>
                      {t('Favorite Event')}
                    </label>
                  </div>

                  <div className='custom-control custom-control-alternative custom-checkbox mb-3'>
                    <input
                      className='custom-control-input'
                      type='checkbox'
                      id='FreeEvent'
                      onChange={(e) => handleChange('isFree', e.target.checked)}
                      checked={formData.isFree}
                    />
                    <label className='custom-control-label' htmlFor='FreeEvent'>
                      {t('No Ticket Sales')}
                    </label>
                  </div>

                  <div className='custom-control custom-control-alternative custom-checkbox mb-3'>
                    <input
                      className='custom-control-input'
                      type='checkbox'
                      id='eventCancelled'
                      onChange={(e) => handleChange('isCancelled', e.target.checked)}
                      checked={formData.isCancelled}
                    />
                    <label className='custom-control-label' htmlFor='eventCancelled'>
                      {t('Event Cancelled')}
                    </label>
                  </div>
                </Col>
                <Col lg='9'>
                  {!formData.isFree &&
                  (user.userSubscription === 'light' || user.userSubscription === 'premium' || user.userType === 'superAdmin') ? (
                    <>
                      <Row>
                        <Col lg='9'>
                          <div className='d-flex justify-content-between pb-2'>
                            <div className='custom-control custom-control-alternative custom-checkbox mb-3'>
                              <input className='custom-control-input' defaultChecked type='checkbox' id='sellTickets' disabled />
                              <label className='custom-control-label' htmlFor='sellTickets'>
                                {t('Do you want to sell tickets through Leventix')}
                              </label>
                            </div>

                            <div className='d-flex align-items-center'>
                              <span className='pr-2'>{t('Currency')}:</span>
                              <select
                                name='currency'
                                className='form-control-alternative form-control'
                                style={{ width: '100px' }}
                                onChange={(e) => handleChange('currency', e.target.value)}
                                value={formData?.currency}
                              >
                                <option value='EUR'>Euro €</option>
                                <option value='USD'>USD $</option>
                              </select>
                            </div>
                          </div>

                          <table className='table'>
                            <thead>
                              <tr>
                                <th>{t('Name')}</th>
                                <th>{t('Price')}</th>
                                <th>{t('Quantity')}</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {formData?.ticketPricing?.map((tp, i) => (
                                <tr key={i}>
                                  <td>
                                    <input
                                      required
                                      className='form-control'
                                      onChange={(e) => handleChange('ticketPricing', e.target.value, i, 'name')}
                                      value={formData.ticketPricing[i].name}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      required
                                      type='number'
                                      className='form-control'
                                      onChange={(e) => handleChange('ticketPricing', e.target.value, i, 'price')}
                                      value={formData.ticketPricing[i].price}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      required
                                      type='number'
                                      className='form-control'
                                      onChange={(e) => handleChange('ticketPricing', e.target.value, i, 'count')}
                                      value={formData.ticketPricing[i].count}
                                    />
                                  </td>
                                  <td>
                                    <Button className='mt-2' color='danger' onClick={() => handleTicketPricing(null, i)} size='sm'>
                                      <i className='fas fa-trash'></i>
                                    </Button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          {/* <hr className='my-2' /> */}
                          <Col className='text-right'>
                            <Button color='primary' onClick={() => handleTicketPricing('add')} size='sm'>
                              <i className='fas fa-plus'></i> {t('Add')}
                            </Button>
                          </Col>
                        </Col>
                        <Col lg='3'>
                          <div className='custom-control custom-control-alternative custom-radio mb-3'>
                            <input
                              className='custom-control-input'
                              id='customRadio1'
                              name='custom-radio-1'
                              type='radio'
                              onChange={(e) => handleChange('ticketType', 'post')}
                              checked={formData.ticketType === 'post'}
                            />
                            <label className='custom-control-label' htmlFor='customRadio1'>
                              {t('Tickets via Post')}
                            </label>
                          </div>
                          <div className='custom-control custom-control-alternative custom-radio mb-3'>
                            <input
                              className='custom-control-input'
                              id='customRadio2'
                              name='custom-radio-1'
                              type='radio'
                              onChange={(e) => handleChange('ticketType', 'email')}
                              checked={formData.ticketType === 'email'}
                            />
                            <label className='custom-control-label' htmlFor='customRadio2'>
                              {t('Tickets via E-Mail')}
                            </label>
                          </div>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>

              <br />
              <br />
              {/* Disclaimer */}
              {/* <h6 className='heading-small text-muted mb-4'>Disclaimer</h6> */}
            </Form>
          </CardBody>
          <CardFooter className='text-center'>
            <Button className='btn-icon btn-3' color='success' type='button' onClick={handleEditEvent} disabled={isLoading}>
              <i className='far fa-calendar-alt'></i>
              <span className='btn-inner--text'>{t('Edit Event')}</span>
            </Button>
          </CardFooter>
        </Card>
      </Container>
    </>
  );
};

export default EditEventPage;
