import { Redirect, Route, Switch } from 'react-router-dom';

import AdminFooter from 'components/Footers/AdminFooter.js';
import AdminNavbar from 'components/Navbars/AdminNavbar.js';

import { Container } from 'reactstrap';
import CustomAlert from 'components/CustomAlert/CustomAlert';
import React from 'react';
import Sidebar from '../components/Sidebar/Sidebar.js';
import leventixLogoPng from '../assets/img/brand/leventix.png';
import routes from 'routes.js';
import { withTranslation } from 'react-i18next';

class Admin extends React.Component {
  state = {};

  componentDidUpdate(prevProps, prevState) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
  }
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === '/admin') {
        return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
      } else {
        return null;
      }
    });
  };
  getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      // if (this.props.location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
      //   return this.props.t(routes[i].name);
      // }
    }
    return 'Leventix';
  };

  render() {
    return (
      <>
        <Sidebar
          {...this.props}
          routes={routes}
          logo={{
            innerLink: '/admin/index',
            imgSrc: leventixLogoPng,
            imgAlt: '...',
          }}
        />
        <div className='main-content' ref='mainContent'>
          <AdminNavbar {...this.props} brandText={this.getBrandText(this.props.location.pathname)} />
          <CustomAlert />
          <Switch>
            {this.getRoutes(routes)}
            <Redirect from='*' to='/admin/index' />
          </Switch>
          <Container fluid>
            <AdminFooter />
          </Container>
        </div>
      </>
    );
  }
}

export default withTranslation()(Admin);
