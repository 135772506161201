// const config = {
//   PUBLIC_URL: 'http://localhost:5000/',
//   WS_BASE_URL: 'http://localhost:5000/api/',
//   DOMAIN_NAME: 'http://localhost:3000',
//   DEMO: true,
// };
const config = {
  PUBLIC_URL: 'https://dev.leventix.de/',
  WS_BASE_URL: 'https://dev.leventix.de/api/',
  DOMAIN_NAME: 'https://backend.leventix.de',
  DEMO: true,
};

export default config;
