import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Table,
} from 'reactstrap';
import React, { useContext, useEffect, useState } from 'react';
import { edit, getAll, deleteUserWithData, sendMail } from '../network/ApiAxios';

import AlertContext from 'context/AlertContext';
import UserContext from 'context/UserContext';
import Header from 'components/Headers/Header.js';
import ReactDatetime from 'react-datetime';
import classnames from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const ManageUsers = () => {
  const { t } = useTranslation();

  const { user } = useContext(UserContext);
  const { updateAlertData } = useContext(AlertContext);

  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [updateUser, setUpdateUser] = useState(null);
  const [updateUserExpiry, setUpdateUserExpiry] = useState(undefined);

  const [tabs, setTabs] = useState(1);
  const [appUsers, setAppUsers] = useState([]);
  const [organizerUsers, setOrganizerUsers] = useState([]);
  const [paidUsers, setPaidUsers] = useState([]);

  const plans = { light: '39.99 €', premium: '59.99 €' };

  // handleDeleteUser
  const handleDeleteUser = async (id) => {
    try {
      // await deleteUser(id);
      await deleteUserWithData(id);
      // refetch users
      getUsers();
    } catch (error) {
      updateAlertData({ success: false, message: error.msg || 'Unknown Error Occurred!' });
    }
  };

  // updateUserSubscription
  const updateUserSubscription = async (user, plan) => {
    try {
      let expiryDate = null;
      if (plans[plan]) {
        expiryDate = new Date(+new Date() + 2592000000).toISOString();
      }
      await edit(user._id, { userSubscription: plan, userSubscriptionExpiry: expiryDate });
      // send email for plan update
      if (plans[plan]) {
        sendMail('admin-plans', { plan: plan, price: `${plans[plan]} €`, sender: user.email, username: `${user.firstName} ${user.lastName}` });
      }
      // refetch users
      getUsers();
    } catch (error) {
      updateAlertData({ success: false, message: error.msg || 'Unknown Error Occurred!' });
    }
  };

  // handleDisableUser
  const handleDisableUser = async (id, disabled = true) => {
    try {
      await edit(id, { disabled: disabled });
      // refetch users
      getUsers();
    } catch (error) {
      updateAlertData({ success: false, message: error.msg || 'Unknown Error Occurred!' });
    }
  };

  // fetchUsers
  const getUsers = async () => {
    const response = await getAll();
    const { data } = response;
    if (data.success) {
      // appUsers
      const users = data.users.filter((user) => user.userType === 'user');
      setAppUsers(users);
      // organizerUsers
      const organizers = data.users.filter((user) => user.userType === 'organizer');
      setOrganizerUsers(organizers);
      // paidUsers
      const premiumOrganizers = organizers.filter((user) => user.userSubscription !== 'basic');
      setPaidUsers(premiumOrganizers);
    }
  };

  // submit update userSubscriptionExpiry
  const handleSubmitUserExpiry = async () => {
    try {
      setIsLoading(true);
      if (updateUser && updateUserExpiry) {
        await edit(updateUser._id, { userSubscriptionExpiry: updateUserExpiry });
        // TODO: send email for plan expiry update
        updateAlertData({ success: true, message: 'Updated successfully!', show: true });
      }
      // reset
      setIsLoading(false);
      setModalOpen(false);
      setUpdateUser(null);
      setUpdateUserExpiry(undefined);
      // refetch users
      getUsers();
    } catch (error) {
      updateAlertData({ success: false, message: error.msg || 'Unknown Error Occurred!' });
    }
  };

  useEffect(() => {
    {
      user.userType === 'superAdmin' ? getUsers() : props.history.push('/');
    }
  }, []);

  return (
    <>
      <Header hideStats={true} />
      {/* Page content */}
      <Container className='mt--7' fluid>
        <Card className='bg-secondary shadow'>
          <CardHeader className='bg-white border-0'>
            <Row className='align-items-center'>
              <Col xs='8'>
                <h3 className='mb-0'>{t('Manage Users')}</h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <div className='nav-wrapper'>
              <Nav className='nav-fill flex-column flex-md-row' id='tabs-icons-text' pills role='tablist'>
                <NavItem>
                  <NavLink
                    aria-selected={tabs === 1}
                    className={classnames('mb-sm-3 mb-md-0 font-weight-bold', { active: tabs === 1 })}
                    onClick={() => setTabs(1)}
                    role='tab'
                  >
                    <i className='fas fa-users mr-2' />
                    {t('App Users')}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    aria-selected={tabs === 2}
                    className={classnames('mb-sm-3 mb-md-0 font-weight-bold', { active: tabs === 2 })}
                    onClick={() => setTabs(2)}
                    role='tab'
                  >
                    <i className='fas fa-user-tie mr-2' />
                    {t('Organizers')}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    aria-selected={tabs === 3}
                    className={classnames('mb-sm-3 mb-md-0 font-weight-bold', { active: tabs === 3 })}
                    onClick={() => setTabs(3)}
                    role='tab'
                  >
                    <i className='fas fa-dollar-sign mr-2' />
                    {t('Paid Organizers')}
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
            <Card className='shadow'>
              <CardBody>
                <TabContent activeTab={'tabs' + tabs}>
                  <TabPane tabId='tabs1'>
                    <Table className='align-items-center table-flush' responsive>
                      <thead className='thead-light'>
                        <tr>
                          <th scope='col'>{t('Display Name')}</th>
                          <th scope='col'>{t('First Name')}</th>
                          <th scope='col'>{t('Last Name')}</th>
                          <th scope='col'>{t('Email')}</th>
                          <th scope='col' />
                        </tr>
                      </thead>
                      <tbody>
                        {appUsers?.map((user) => (
                          <tr key={user._id}>
                            <td>{user.displayName}</td>
                            <td>{user.firstName}</td>
                            <td>{user.lastName}</td>
                            <td>{user.email}</td>
                            <td className='text-right'>
                              <>
                                <Button
                                  className='btn-icon btn-2'
                                  color={user.disabled ? 'success' : 'default'}
                                  onClick={() => handleDisableUser(user._id, !user.disabled)}
                                >
                                  {user.disabled ? <i className='fas fa-user-check' /> : <i className='fas fa-ban' />}
                                </Button>
                                <Button className='btn-icon btn-2' color='danger' onClick={() => handleDeleteUser(user._id)}>
                                  <i className='fas fa-trash-alt' />
                                </Button>
                              </>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </TabPane>
                  {/* organizerUsers */}
                  <TabPane tabId='tabs2'>
                    <Table className='align-items-center table-flush' responsive>
                      <thead className='thead-light'>
                        <tr>
                          <th scope='col'>{t('Display Name')}</th>
                          <th scope='col'>{t('Email')}</th>
                          <th scope='col'>{t('Mobile')}</th>
                          <th scope='col'>{t('Subscription')}</th>
                          <th scope='col'>{t('Expiry')}</th>
                          <th scope='col' />
                        </tr>
                      </thead>
                      <tbody>
                        {organizerUsers?.map((user) => (
                          <tr key={user._id}>
                            {/* <td>{user.displayName || `${user.firstName} ${user.lastName}`}</td> */}
                            <td>{user.displayName}</td>
                            <td>{user.email}</td>
                            <td>{user.mobile}</td>
                            <td>
                              {!user.disabled ? (
                                <>
                                  <Button
                                    className='custom-btn'
                                    disabled={user.userSubscription === 'basic'}
                                    onClick={() => updateUserSubscription(user, 'basic')}
                                  >
                                    {t('basic')}
                                  </Button>
                                  <Button
                                    className='custom-btn'
                                    disabled={user.userSubscription === 'light'}
                                    onClick={() => updateUserSubscription(user, 'light')}
                                  >
                                    {t('light')}
                                  </Button>
                                  <Button
                                    className='custom-btn'
                                    disabled={user.userSubscription === 'premium'}
                                    onClick={() => updateUserSubscription(user, 'premium')}
                                  >
                                    {t('premium')}
                                  </Button>
                                </>
                              ) : null}
                            </td>
                            <td className='d-flex align-items-center font-weight-bold'>
                              <Button
                                className='btn-icon btn-2 mr-3'
                                color={'secondary'}
                                disabled={user.userSubscription === 'basic'}
                                onClick={() => {
                                  setModalOpen(true);
                                  setUpdateUser(user);
                                }}
                              >
                                <i className='fas fa-cog' />
                              </Button>
                              {user.userSubscriptionExpiry ? moment(user.userSubscriptionExpiry).format('DD.MM.YYYY HH:MM') : null}
                            </td>
                            <td className='text-right'>
                              <>
                                <Button
                                  className='btn-icon btn-2'
                                  color={user.disabled ? 'success' : 'default'}
                                  onClick={() => handleDisableUser(user._id, !user.disabled)}
                                >
                                  {user.disabled ? <i className='fas fa-user-check' /> : <i className='fas fa-ban' />}
                                </Button>
                                <Button className='btn-icon btn-2' color='danger' onClick={() => handleDeleteUser(user._id)}>
                                  <i className='fas fa-trash-alt' />
                                </Button>
                              </>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </TabPane>
                  {/* paidUsers */}
                  <TabPane tabId='tabs3'>
                    <Table className='align-items-center table-flush' responsive>
                      <thead className='thead-light'>
                        <tr>
                          <th scope='col'>{t('First Name')}</th>
                          <th scope='col'>{t('Last Name')}</th>
                          <th scope='col'>{t('Email')}</th>
                          <th scope='col'>{t('Mobile')}</th>
                          <th scope='col'>{t('Subscription')}</th>
                          <th scope='col'>{t('Expiry')}</th>
                          <th scope='col' />
                        </tr>
                      </thead>
                      <tbody>
                        {paidUsers?.map((user) => (
                          <tr key={user._id}>
                            <td>{user.firstName}</td>
                            <td>{user.lastName}</td>
                            <td>{user.email}</td>
                            <td>{user.mobile}</td>
                            <td className='text-uppercase'>
                              <b>{t(user.userSubscription)}</b>
                            </td>
                            <td className='text-uppercase'>
                              <b>{t(user.userSubscriptionExpiry)}</b>
                            </td>
                            <td className='text-right'>
                              <>
                                <Button
                                  className='btn-icon btn-2'
                                  color={user.disabled ? 'success' : 'default'}
                                  onClick={() => handleDisableUser(user._id, !user.disabled)}
                                >
                                  {user.disabled ? <i className='fas fa-user-check' /> : <i className='fas fa-ban' />}
                                </Button>
                                <Button className='btn-icon btn-2' color='danger' onClick={() => handleDeleteUser(user._id)}>
                                  <i className='fas fa-trash-alt' />
                                </Button>
                              </>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </CardBody>
        </Card>
      </Container>

      {/* update userSubscriptionExpiry */}
      <Modal className='modal-dialog-centered' isOpen={modalOpen} toggle={() => setModalOpen(false)} backdrop='static'>
        <div className='modal-header'>
          <h5 className='modal-title' id='exampleModalLabel'>
            {t('Update')} {t('Expiry')}
          </h5>
          <button aria-label='Close' className='close' data-dismiss='modal' type='button' onClick={() => setModalOpen(false)}>
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        {/* <div className='modal-body'> */}
        <div className=''>
          <Card className='bg-secondary shadow border-0'>
            <CardBody className='px-lg-5 py-lg-5'>
              <FormGroup>
                <label className='form-control-label'>{t('Expiry')}</label>
                <InputGroup className='input-group-alternative'>
                  <InputGroupAddon addonType='prepend'>
                    <InputGroupText>
                      <i className='ni ni-calendar-grid-58' />
                    </InputGroupText>
                  </InputGroupAddon>
                  <ReactDatetime
                    inputProps={{ placeholder: t('Expiry') }}
                    isValidDate={(current) => current > moment().subtract(1, 'days')}
                    onChange={(e) => (e ? setUpdateUserExpiry(e.toISOString()) : null)}
                    value={moment(updateUserExpiry).format('DD.MM.YYYY HH:MM')}
                  />
                </InputGroup>
              </FormGroup>

              <div className='text-center'>
                <Button className='my-4' color='success' onClick={handleSubmitUserExpiry} disabled={isLoading}>
                  {t('Save')}
                </Button>
              </div>
            </CardBody>
          </Card>
        </div>
      </Modal>
    </>
  );
};

export default ManageUsers;
